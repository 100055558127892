import { Provider } from "react-redux";

import { renderAll } from "Helpers/ReactHelpers";
import { EditStation } from "Station/EditStation";

import { buildStore } from "./Store";

const elements = document.querySelectorAll("div[data-react=EditStation]");

if (elements.length > 0) {
	const { ActionProvider, actions, store } = buildStore();

	renderAll(elements, () => (
		<Provider store={store}>
			<ActionProvider value={actions}>
				<EditStation />
			</ActionProvider>
		</Provider>
	));
}
