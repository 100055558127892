import { shallowEqual } from "react-redux";

import { BackButton, DeleteButton, PlayButton, RestoreButton, SaveButton, StarButton } from "Components/Buttons";
import { createStation, deleteStation, restoreStation, updateStation } from "Station/StationStore";

import { AppState, useActions, useStateSelector } from "../Store";

import { EditStationEndpoints } from "./EditStationEndpoints";
import { EditStationInfo } from "./EditStationInfo";
import { EditStationTags } from "./EditStationTags";
import { StationMetadata } from "./StationMetadata";

function showCreateForm(state: AppState) {
	const station = state.station.station;

	return station.id == null || station.id.length === 0;
}

function InnerForm() {
	const station = useStateSelector(state => state.station.station);

	return (
		<>
			<div className="d-flex flex-column flex-lg-row gap-4">
				<div className="col">
					<EditStationInfo />
				</div>
				<div className="col">
					<EditStationTags />
				</div>
				<div className="col">
					<StationMetadata station={station} />
				</div>
			</div>
			<br />
			<EditStationEndpoints />
		</>
	);
}

function CreateForm() {
	const [dispatch, actions] = useActions();
	const appState = useStateSelector(state => ({
		blocked: state.station.operationInProgress,
		failed: state.station.operationFailed
	}), shallowEqual);

	return (
		<article className={"card focus" + (appState.failed ? " error" : "")}>
			<div className="card-body">
				<nav className="btn-toolbar float-end">
					<div className="btn-group">
						<SaveButton onClick={() => { void dispatch(createStation(actions)); }} text="Create" disabled={appState.blocked} />
					</div>
				</nav>
				<h1>
					{appState.failed ? <i style={{ color: "#ff7744" }} className="bi bi-exclamation-circle-fill me-3"></i> : ""}
					Create new station
				</h1>
				<InnerForm />
			</div>
		</article>
	);
}

function EditForm() {
	const [dispatch, actions] = useActions();
	const props = useStateSelector(state => ({
		blocked: state.station.operationInProgress,
		failed: state.station.operationFailed,
		station: state.station.station
	}), shallowEqual);

	return (
		<div>
			{props.station.deleted != null &&
				<div className="alert alert-danger">
					<i className="bi bi-trash-fill me-2"></i><strong>This station has been disabled.</strong>
				</div>
			}
			{props.station.deleted == null && props.station.active === false &&
				<div className="alert alert-warning">
					<i className="bi bi-exclamation-triangle-fill me-2"></i><strong>This station has been flagged as inactive.</strong>
				</div>
			}
			<article className={"card focus" + (props.failed ? " error" : "")}>
				<div className="card-body">
					<nav className="btn-toolbar float-lg-end mb-4 mb-lg-0 justify-content-end">
						<div className="btn-group me-2">
							<BackButton href={props.station.links.page} text="Back to station" />
						</div>
						<div className="btn-group me-2">
							{!props.station.deleted
								? <DeleteButton onClick={() => { void dispatch(deleteStation(actions)); }} text="Delete" disabled={props.blocked} />
								: <RestoreButton onClick={() => { void dispatch(restoreStation(actions)); }} text="Restore" disabled={props.blocked} />}
						</div>
						<div className="btn-group me-2">
							<SaveButton onClick={() => { void dispatch(updateStation(actions)); }} text="Save" disabled={props.blocked} />
						</div>
						<div className="btn-group d-none d-lg-inline-flex">
							<StarButton station={props.station} />
							<PlayButton station={props.station} text={true} />
						</div>
					</nav>
					<h1>
						{props.failed ? <i style={{ color: "#ff7744" }} className="bi bi-exclamation-circle-fill me-3"></i> : ""}
						Editing {props.station.title}
					</h1>
					<InnerForm />
				</div>
			</article>
		</div>
	);
}

export function EditStation() {
	return useStateSelector(showCreateForm) ? <CreateForm /> : <EditForm />;
}
