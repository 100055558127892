import { shallowEqual } from "react-redux";

import { AddItemInput } from "Components/AddItemInput";
import { Badge } from "Components/Badge";
import { DeleteButton } from "Components/Buttons";
import { friendlyDate } from "Helpers/DateHelpers";

import { useActions, useStateSelector } from "../Store";

function EndpointList() {
	const [dispatch, actions] = useActions();
	const endpoints = useStateSelector(state => state.station.station.endpoints);

	if (endpoints.length === 0) {
		return (
			<span>No endpoints, add at least one endpoint.</span>
		);
	}

	return (
		<div className="list-group list-group-flush">
			{endpoints.map((endpoint, index) => (
				<div className="list-group-item d-flex align-items-center gap-3 px-0" key={endpoint.href}>
					<div className="btn-group flex-column">
						<button className="btn btn-link p-1" disabled={index === 0} onClick={() => dispatch(actions.station.moveStationEndpointUp({ href: endpoint.href }))}>
							<i className="bi bi-caret-up-fill"></i>
						</button>
						<button className="btn btn-link p-1" disabled={index + 1 === endpoints.length} onClick={() => dispatch(actions.station.moveStationEndpointDown({ href: endpoint.href }))}>
							<i className="bi bi-caret-down-fill"></i>
						</button>
					</div>
					<span className="d-inline-flex flex-column gap-1">
						<a href={endpoint.href} style={{ lineBreak: "anywhere" }}>{endpoint.href}</a>
						<span className="d-inline-flex flex-column flex-sm-row align-items-start gap-1">
							{endpoint.successful != null && endpoint.successful > 0 &&
								<Badge type="success" message={`${friendlyDate(endpoint.lastSuccessful)} / ${endpoint.successful}`} />
							}
							{endpoint.failed != null && endpoint.failed > 0 &&
								<Badge type="danger" message={`${friendlyDate(endpoint.lastFailed)} / ${endpoint.failed}`} />
							}
						</span>
					</span>
					<span className="flex-grow-1"></span>
					<DeleteButton onClick={() => dispatch(actions.station.removeStationEndpoint({ href: endpoint.href }))} />
				</div>
			))}
		</div>
	);
}

export function EditStationEndpoints() {
	const [dispatch, actions] = useActions();
	const appState = useStateSelector(state => ({
		dirty: state.station.dirty,
		endpoints: state.station.station.endpoints
	}), shallowEqual);

	const danger = appState.endpoints.length === 0 && appState.dirty;

	return (
		<div>
			<h4 className={(danger ? "text-danger" : "")}>Endpoints</h4>
			<div className="mb-3">
				<EndpointList />
			</div>
			<AddItemInput id="newendpoint" label="Add new endpoint" placeholder="URL"
				pattern={/^\w+:\/\/.+$/}
				onAdd={href => dispatch(actions.station.addStationEndpoint({ href }))}
			/>
		</div>
	);
}
