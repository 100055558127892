import { IStation } from "Api/V1//Models";
import { friendlyDate } from "Helpers/DateHelpers";

export function StationMetadata(props: { station: IStation }) {
	if (!props.station.id) {
		return null;
	}

	return (
		<div>
			<h4>Metadata</h4>
			<table className="table w-100">
				<tbody>
					<tr style={{ display: props.station.updated ? "table-row" : "none" }}>
						<td>Updated</td>
						<td><strong>{friendlyDate(props.station.updated)}</strong></td>
					</tr>
					<tr style={{ display: props.station.deleted ? "table-row" : "none" }}>
						<td>Deleted</td>
						<td><strong style={{ color: "red" }}>{friendlyDate(props.station.deleted)}</strong></td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}
