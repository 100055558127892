import { shallowEqual } from "react-redux";

import { TextInput, TextInputWithButton } from "Components/Inputs";

import { useActions, useStateSelector } from "../Store";

export function EditStationInfo() {
	const [dispatch, actions] = useActions();
	const appState = useStateSelector(state => ({
		dirty: state.station.dirty,
		station: state.station.station
	}), shallowEqual);

	return (
		<fieldset>
			<legend>Basic info</legend>
			<TextInput id="title" label="Title" className="mb-3"
				value={appState.station.title} required={true}
				dirty={appState.dirty}
				onChange={title => dispatch(actions.station.editStationTitle({ title }))}
			/>
			<TextInputWithButton id="website" buttonClass="btn-outline-secondary" iconClass="bi-box-arrow-up-right"
				label="Website"
				placeholder="https://"
				value={appState.station.links.website}
				dirty={appState.dirty}
				required={true}
				pattern={/^https?:\/\/.+$/}
				onChange={url => dispatch(actions.station.editStationWebsite({ url }))}
				buttonHref={appState.station.links.website}
			/>
		</fieldset>
	);
}
