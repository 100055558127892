import { useState } from "react";

import { TextInputWithButton } from "./Inputs";

export interface IAddItemInputProps {
	id: string;
	label: string;
	placeholder?: string;
	items?: string[];
	pattern?: RegExp;

	onAdd: (value: string) => void;
}

export interface IAddItemInputState {
	autoCompleteIndex: number;
	autoCompleteVisible: boolean;
	value: string;
}

function AutoCompleteTable(props: { matches: string[]; index: number; onSelect: (match: string) => void }) {
	return (
		<div style={{ position: "relative" }}>
			<table className="table table-bordered" style={{ background: "#fff", position: "absolute", zIndex: 10 }}>
				<tbody>
					{props.matches.map((match, index) => {
						const selected = props.index === index;

						return (
							<tr key={match}>
								<td className={"p-0 " + (selected ? "" : "light")} style={{ background: selected ? "#fafafa" : "#fff", fontWeight: selected ? 600 : 400 }}>
									<button className="btn btn-link text-start w-100" onClick={() => props.onSelect(match)}>{match}</button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

export function AddItemInput(props: IAddItemInputProps) {
	const [state, setState] = useState<IAddItemInputState>({
		autoCompleteIndex: 0,
		autoCompleteVisible: false,
		value: ""
	});

	function getMatches(): string[] {
		if (!state.autoCompleteVisible || state.value === "" || props.items == null) {
			return [];
		}

		const lowerValue = state.value.toLowerCase();

		return props.items.filter(i => i.toLowerCase().includes(lowerValue)).slice(0, 10);
	}

	function onChange(value: string) {
		setState({ autoCompleteIndex: 0, autoCompleteVisible: true, value });
	}

	function onSelectAuto(value: string) {
		setState({ autoCompleteIndex: 0, autoCompleteVisible: false, value });
	}

	function onKeyPress(event: React.KeyboardEvent<HTMLInputElement>): boolean {
		if (!state.autoCompleteVisible) {
			return false;
		}

		if (event.key === "Tab") {
			const value = getMatches()[state.autoCompleteIndex];
			if (value) {
				setState({ autoCompleteIndex: 0, autoCompleteVisible: false, value });
				event.preventDefault();
			}
		}

		if (event.key === "ArrowUp") {
			setState(prev => ({ ...prev, autoCompleteIndex: Math.max(0, prev.autoCompleteIndex - 1) }));
			event.preventDefault();
		}

		if (event.key === "ArrowDown") {
			const matches = getMatches();
			setState(prev => ({ ...prev, autoCompleteIndex: Math.min(matches.length - 1, state.autoCompleteIndex + 1) }));
			event.preventDefault();
		}

		return true;
	}

	function onSubmit() {
		if (state.value.length > 0) {
			props.onAdd(state.value);
			setState({ autoCompleteIndex: 0, autoCompleteVisible: false, value: "" });
		}
	}

	return (
		<div>
			<form onSubmit={e => { e.preventDefault(); onSubmit(); }}>
				<TextInputWithButton buttonClass="btn-success" iconClass="bi-plus-lg" autoComplete="off"
					id={props.id}
					label={props.label}
					placeholder={props.placeholder}
					value={state.value}
					pattern={props.pattern}
					onChange={onChange}
					onKeyPress={onKeyPress}
					onClick={() => onSubmit()}
				/>
			</form>
			<AutoCompleteTable matches={getMatches()} index={state.autoCompleteIndex} onSelect={onSelectAuto} />
		</div>
	);
}
