import { useRef, useState } from "react";

export interface ITextInputProps {
	id?: string;
	className?: string;
	value: string;
	label?: string;
	placeholder?: string;
	required?: boolean;
	pattern?: RegExp;
	autoComplete?: string;
	dirty?: boolean;

	onChange: (value: string) => void;
	onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => boolean;
}

interface ITextInputState {
	dirty: boolean;
}

function isValid(props: ITextInputProps) {
	const isEmpty = props.value.length === 0;
	if (!isEmpty && props.pattern != null && !props.pattern.test(props.value)) {
		return false;
	}

	if (isEmpty && props.required === true) {
		return false;
	}

	return true;
}

function isDirty(props: ITextInputProps, state: ITextInputState) {
	return props.dirty ?? state.dirty;
}

function showValidationError(props: ITextInputProps, state: ITextInputState) {
	return isDirty(props, state) && !isValid(props);
}

export function TextInput(props: ITextInputProps) {
	const [state, setState] = useState<ITextInputState>({ dirty: false });

	return (
		<div className={props.className}>
			{props.label != null ? <label htmlFor={props.id}>{props.label}</label> : ""}
			<input className={"form-control" + (showValidationError(props, state) ? " is-invalid" : "")} type="text"
				id={props.id}
				value={props.value}
				placeholder={props.placeholder}
				autoComplete={props.autoComplete}
				onChange={e => { setState({ dirty: true }); props.onChange(e.currentTarget.value); }}
				onKeyDown={props.onKeyPress}
				required={props.required}
			/>
		</div>
	);
}

export interface ITextInputWithButtonProps extends ITextInputProps {
	buttonClass: string;
	buttonHref?: string;
	iconClass: string;

	onClick?: (value: string) => void;
}

export function TextInputWithButton(props: ITextInputWithButtonProps) {
	const inputRef = useRef<HTMLInputElement>(null);
	const [state, setState] = useState<ITextInputState>({ dirty: false });
	const icon = <i className={"bi " + props.iconClass}></i>;

	function onClick() {
		setState({ dirty: true });

		if (props.onClick && inputRef.current) {
			props.onClick(inputRef.current.value);
		}
	}

	return (
		<div>
			{props.label != null ? <label htmlFor={props.id}>{props.label}</label> : ""}
			<div className="input-group">
				<input className={"form-control" + (showValidationError(props, state) ? " is-invalid" : "")} type="text"
					ref={inputRef}
					id={props.id}
					value={props.value}
					placeholder={props.placeholder}
					autoComplete={props.autoComplete}
					onChange={e => { setState({ dirty: true }); props.onChange(e.currentTarget.value); }}
					onKeyDown={props.onKeyPress}
					required={props.required}
				/>
				{props.buttonHref
					? <a className={"btn " + props.buttonClass} href={props.buttonHref} target="_blank" rel="noopener noreferrer">{icon}</a>
					: <button className={"btn " + props.buttonClass} onClick={onClick} disabled={props.value.length === 0}>{icon}</button>}
			</div>
		</div>
	);
}
